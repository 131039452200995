*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

html {
  --cardBlack: #2A3041;
  --cardBlue:#1F4A82;
  --projectNumberWhite: rgba(231, 238, 241, 0.91);
  --devBlue: #061931;
  --devYellow: #F6F3EB;
  --devPink: #d5917dde;
  --devLightBlue: #91C6C2;
  --devGreen: #759F9B;
  --devGreyBlue: #444C63;
  --devOrange: #BE7865;
  --devGoldenBrown: #504e47;
  --devBoxShadowGrey: #717171;
  --devPurple: #aa4cff;
}

body {
    /* font-family: 'Eurostile', 'Arial', sans-serif; */
  font-family: 'Arial', sans-serif;
  padding: 0;
  margin: 0;
  background-color: #e6e5e112;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1 {
  text-align: center;
  font-size: 2rem;
  line-height: 70px;
}

/************************  NAV  ************************/

.logo{
    letter-spacing: 3px;
}

.logo a {
    text-decoration: none;
    color: #fff;
}

#menu-icon {
    display: none;
}

#menu-icon:hover {
    cursor: pointer;
}

nav { 
    background: rgb(6,25,49);
    background: linear-gradient(25deg, rgba(6,25,49,1) 0%, rgba(36,85,147,1) 0%, rgba(6,25,49,1) 0%, rgba(36,85,147,1) 100%);
    height: 70px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    position: fixed;
    width: 100%;
    z-index: 1;
    box-shadow: 1px 2px 7px 3px #3c537c7a;
}

.nav-links {
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    width: 600px;
}

.nav-links li {
    font-size: 1.25rem;

}

.nav-links li a {
    text-decoration: none;
    color: #fff;
    -webkit-transition: all 350ms;
    -o-transition: all 350ms;
    transition: all 350ms;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.nav-links li a:hover {
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
}


/************************  NAV END ************************/

/************************  ABOUT  ************************/

.about-container {
    /* margin-bottom: 200px; */
    padding-top: 70px;
    background: #fff url(images/bg.png) repeat;
    /* border: 1px solid #000; */
}

.about-headings {
    text-decoration: underline;
    font-size: 1.625rem;
}

.about-me-list-items li {
    padding: 5px;
}

.about-text {
    font-size: 1.625rem;
    font-weight: 400;
    max-width: 55%;
    margin: 0 auto;
    line-height: 1.5;
    color: #4d4d4d;
    text-align: center;
}

.about-me-text {
    text-align: left;
}

.about-youtube-icon {
    color: #fc0e0ec4;
    font-size: 2.3rem;
}

.about-youtube-link {
    color: #e21d15;
    text-decoration: none;
}

#bio-content {
    color: var(--devLightBlue);
}

.chevron-down {
    width: 40px;
    transform: translateY(40px) rotate(90deg);
    display: block;
    margin: 0 auto;
}

.gregg-img {
    display: block;
    margin: 50px auto 100px;
    width: 55%;
    filter: saturate(80%);
    box-shadow: -3px 2px 10px 6px #3e4d604a;
}

.gregg-secondary-img {
    text-align: center;
    margin: 0 auto;
    width: 30%;
    display: block;
    border-radius: 5px;
    filter: brightness(110%);
}

#header-name, #header-subtitle {
    -webkit-animation-name: leadin;
            animation-name: leadin;
    -webkit-animation-duration: 1.4s;
            animation-duration: 1.4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
}

@-webkit-keyframes leadin{
    0%{
        opacity: 0;
    }
    100% {
         opacity: 1;
    }
}

@keyframes leadin{
    0%{
        opacity: 0;
    }
    100% {
         opacity: 1;
    }
}

#header-name {
    text-align: center;
    font-size: 4rem;
    letter-spacing: 3px;
    margin-bottom: 30px;
}

#header-subtitle {
    text-align: center;
    font-size: 3.18rem;
    margin-top: -30px;
    font-weight: 400;
    letter-spacing: -1.8px;
    color: #474d5de6;

}


/************************  ABOUT END ************************/

/************************  SKILLS  ************************/

.skill-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 900px;
    margin: auto;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-around;
    padding-bottom: 60px;
}

.skill {
  -ms-flex-preferred-size: 27%;
      flex-basis: 27%;
  list-style-type: none;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.section-container {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 120px;
  /* padding: 60px 0 100px; */
  background-color: #d5d7d845;
  color: #4d4d4d;
}

.skills-divider {
    height: 3px;
    background-color: #67707b66;
    margin: -40px auto 80px;
    width: 400px;
}

.show {
    width: 470px;
}

#skills-header, #projects-header, #about-header {
   color: #363535; 
   margin-bottom: 53px;
   margin-top: 90px;
   margin-top: 80px;
   letter-spacing: 5px;
}

#about-header {
    text-align: center;
    font-size: 1.875rem;
}

/************************  SKILLS END ************************/

/************************  PROJECTS ************************/

.project-cards {
    margin-bottom: 70px;
    background: #fff url(images/bg.png) repeat;
}

.project-card-box {
    width: 1200px;
    height: 600px;
    margin: 40px auto 80px;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 12px;
    -webkit-box-shadow: 4px 4px 20px #999;
            box-shadow: 4px 4px 20px #999;

}

#project-card-1 {
    background-image: url('./images/music-library-crop.png');
    background-color: var(--devYellow);
    background-position: -0.2%, 100%; 
    background-size: contain;
}

#project-card-2 {
    background-image: url('./images/guitar-site-crop.jpg');
    background-color: var(--devYellow);
    background-position: -14%, 100%; 
}

#project-card-3 {
    background-image: url('./images/rhythmic-ear-trainer.png');
    background-color: var(--devPurple);
}

#project-card-4 {
    background-image: url('./images/synth-explorer.png');
}

#project-card-5 {
    background-image: url('./images/music_social-site.png');
    background-color: var(--devYellow);
    background-position: -0.2%, 100%; 
}

#project-card-6 {
    background-image: url('./images/ear-training-game-new-crop.png');
    background-color: var(--devYellow);
    background-size: 63%;
    background-position: -1% 0%;
}

#project-card-7{
    background-image: url('./images/wordpress-site.jpg');
    background-color: var(--devYellow);
    background-size: 63%;
    background-position: -1% 0%;
}

#project-card-jazz-guitar{
    background-image: url('./images/jazz-guitar-game.png');
    background-color: var(--devPurple);
    background-size: 63%;
    background-position: -1% 0%;
}



.project-card-content-box {
    width: 799px;
    height: 600px;
    float: right;
    position: relative;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.project-case-study {
    color: #fff;
    font-size: 1.25rem;
    max-width: 95%;
    margin: 20px auto 0px;
    min-height: 125px;
    line-height: 1.3;
    padding-left: 10px;
}

.project-content {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.project-card-blue {
    background-color: var(--cardBlue);
}

.project-card-black {
    background-color: var(--cardBlack);
}

.project-number {
    font-family: Times;
    font-weight: 600;
    font-size: 7.5rem;
    position: absolute;
    left: 77px;
    top: -100px;
    color: var(--projectNumberWhite);
}

.project-title {
    color: rgba(248, 254, 252, 0.91);
    font-size: 2.8rem;
    text-align: center;
}

#project-title-music-library {
    line-height: 1;
    max-width: 300px;
    margin: 30px auto 35px;
}

#project-title-music-library, #project-title-guitar-website, #project-title-cms-website, #project-title-synth-explorer {
    color: rgba(231, 238, 241, 0.91);
}

#project-title-tutorial-search, #project-title-statement-coder  {
    color:rgba(58, 61, 79, 0.91);
}

#project-title-wordpress-site {
    padding-left: 60px;
}

.project-subtitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    color: #F8FEFC;
    font-size: 1.44rem;
    font-weight: normal;
}

#project-subtitle-tutorial-search, #project-subtitle-statement-coder {
    color: #888080;
    font-size: 1.44rem;
    font-weight: normal;
}

#project-subtitle-guitar-website, #project-subtitle-cms-website, #project-subtitle-synth-explorer {
    color: #eee;
}

.tech, .tech2, .tech3 {
    margin-right: 10px;
}

.tech, .tech2, .tech3 {
    font-size: 1.125rem;
    border: 1px solid rgba(250,250,250,0.5);
    padding: 10px;
    border-radius: 6px;
}

.tech3 {
    padding: 13px; 
}

#project-description-tutorial-search {
    color: rgba(58, 61, 79, 0.91);
}

#project-description-ear-training-game {
    color: rgba(248, 254, 252, 0.91);
}

.anchor-btn {
    background-color: #fff;
    color: #000;
    border-radius: 6px;
    outline: none;
    text-decoration: none;
    transition: 300ms;
    font-size: 1.25rem;
    padding: 0.75em;
}

.btn-group {
    width: 50%;
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
}

.anchor-btn:hover {
    box-shadow: 1px 1px 10px 5px #95a7a39e;
}

button {
    border: none;
    background-color: var(--devGreen);
    color: #fff;
    border-radius: 6px;
    width: 140px;
    height: 50px;
    font-size: 1.125rem;
    outline: none;
}

button a {
    text-decoration: none;
    color: #fff;
}

button:hover {
    cursor: pointer;
}

/************************  PROJECTS END ************************/


/************************  FOOTER  ************************/

.footer{
    background: rgb(6,25,49);
    background: linear-gradient(0deg, rgba(6,25,49,1) 0%, rgba(36,85,147,1) 0%, rgba(6,25,49,1) 0%, rgba(36,85,147,1) 100%);
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 80px;
    margin-top: auto;
    clip-path: polygon(0% 15%, 100% 0%, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0% 15%, 100% 0%, 100% 100%, 0% 100%);
}

.footer-name {
    padding-top: 40px;
    padding-top: 100px;
    font-size: 3.125rem;
    font-weight: 400;
    color: var(--devYellow);
    transition: all 600ms;
    text-align: center;
    line-height: 70px;
    letter-spacing: 3px;
}

.footer-name-reveal {
    transform: translateY(0px);
}

.footer-scroll-up-wrapper {
    text-align: right;
    padding-right: 50px;
}

.footer-scroll-up {
    width: 50px;
    border-radius: 50%;
    background-color: #fff;
    filter: hue-rotate(200deg);
    transform: rotate(270deg);
    transition: all 400ms;
}

.footer-scroll-up:hover {
    cursor: pointer;
    transform: rotate(270deg) scale(1.15);
}

.footer-site-design-credits {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
}

.footer-site-design-credits-links {
    text-decoration: none;
    color: #fff;
}

.footer-icon-credits {
    text-align: center;
    font-size: 0.7rem;
    max-width: 200px;
    margin: 0 auto;
}

#mail-container {
    margin-bottom: 30px;
}

.mail-container-reveal {
    opacity: 1;
}

.footer-line {
    height: 1px;
    width: 26.56em;
    background-color: rgba(250,250,250,0.5);
    margin: auto;
}

#footer-message {
    text-align: center;
    font-size: 1.125rem;
}

#envelope {
    text-align: center;
    font-size: 3.75rem;
    color: #fff3b7e8;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    -o-transition: transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
}

#envelope{
    width: 90px;
}

.envelope-link{
    width: 90px;
    margin: 0 auto;
    text-align: center;
}

#envelope:hover {
    animation: shake 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.footer-links {
    padding-top: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style-type: none;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.social [class*="fa fa-"] {
    text-decoration: none;
    background-color: var(--devYellow);
    border-radius: 30px;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    margin: auto 15px;
    width: 50px;
    font-size: 30px;
    text-align: center;
    margin-right: 20px;
    -webkit-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    border: 2px solid #fff;
}

.social [class*="fa fa-"]:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
            transform: scale(1.2);
}

/************************  FOOTER END ************************/


/* ======================================================================= */

/************************  MEDIA QUERIES  ************************/

@media screen and (max-width: 1250px) {
    .anchor-btn {
        outline: none;
        text-decoration: none;
        font-size: 1.03rem;
    }

    .project-card-box {
        width: 1000px;
        height: 400px;
        margin: 40px auto;
        background-size: 400px 500px;
    }
    
    .project-card-content-box{
        width: 700px;
        height: 400px;
    }

    .project-case-study  {
        font-size: 1rem;
        margin: 0px auto 0px;
        min-height: 100px;
    }

    .project-case-study-guitar-website {
        line-height: 1.4;
    }

    .project-content {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
    }

    .project-title {
        padding-top: 10px;
        font-size: 2.18rem;
    }

    .project-subtitle {
        padding-top: 10px;
    }

    .tech, .tech2, .tech3 {
        font-size: 1.06rem;
        padding: 8px;
    }

    .tech3 {
        padding: 11px; 
    }

    .project-description {
        margin-top: 50px;
    }

   #project-title-music-library {
        max-width: 340px;
        margin: auto;
        margin-bottom: 10px;
        padding-top: 40px;
    }
}
/* ========================================================================== */

@media screen and (max-width: 1000px) {


    .logo {
        visibility: hidden;
    }

    .project-card-box {
        width: 800px;
        height: 400px;
        margin: 40px auto;
        background-size: contain;
    }
    
    .project-card-content-box {
        width: 550px;
        height: 400px;
    }

    .project-number {
        font-size: 4.35rem;
        left: 47px;
        top: -40px;
    }


   #project-title-music-library {
        max-width: 340px;
        margin: auto;
        padding-top: 30px;
    }

    #project-title-wordpress-site {
        padding-left: 40px;
        padding-top: 0;
    }

    .tech, .tech2, .tech3 {
        font-size: 1.06rem;
        padding: 7px;
    }

    .tech3 {
        padding: 10px; 
    }
}

/* =========================================================================== */
@media screen and (max-width: 1500px){
    .about-text {
        max-width: 75%;
    }
    .gregg-secondary-img {
        width: 50%;
    }
    .gregg-img {
        width: 90%;
    }
}

@media screen and (max-width: 800px) {

    .about-text {
        font-size: 1.44rem;
        max-width: 80%;
        /* margin-bottom: -140px; */
    }

    .gregg-secondary-img {
        width: 90%;
    }

    .close-x-top {
        -webkit-transform: rotate(45deg) scaleX(1.25);
            -ms-transform: rotate(45deg) scaleX(1.25);
                transform: rotate(45deg) scaleX(1.25);
    }

    .close-x-middle {
        opacity: 0;
        -webkit-transform: scaleX(0);
            -ms-transform: scaleX(0);
                transform: scaleX(0);
    }

    .close-x-bottom {
        -webkit-transform: rotate(-45deg) scaleX(1.25) translateY(1px);
            -ms-transform: rotate(-45deg) scaleX(1.25) translateY(1px);
                transform: rotate(-45deg) scaleX(1.25) translateY(1px);
    }


    #menu-icon {
        width: 20px;
        height: 19px;
        position: absolute;
        top: 20px;
        right: 20px;
        display: block;
    }

    #menu-icon-top {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 3px;
        background-color: #fff;
        -webkit-transform-origin: 0 0;
            -ms-transform-origin: 0 0;
                transform-origin: 0 0;
        -webkit-transition: -webkit-transform 300ms ease-out;
        transition: -webkit-transform 300ms ease-out;
        -o-transition: transform 300ms ease-out;
        transition: transform 300ms ease-out;
        transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
    }

    #menu-icon-middle {
        position: absolute;
        top: 8px;
        left: 0;
        width: 20px;
        height: 3px;
        background-color: #fff;
        -webkit-transition: all 300ms ease-out;
        -o-transition: all 300ms ease-out;
        transition: all 300ms ease-out;
        -webkit-transform-origin: 0 50%;
            -ms-transform-origin: 0 50%;
                transform-origin: 0 50%;
    }

    #menu-icon-bottom {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 20px;
        height: 3px;
        background-color: #fff;
        -webkit-transform-origin: 0 100%;
            -ms-transform-origin: 0 100%;
                transform-origin: 0 100%;
        -webkit-transition: -webkit-transform 300ms ease-out;
        transition: -webkit-transform 300ms ease-out;
        -o-transition: transform 300ms ease-out;
        transition: transform 300ms ease-out;
        transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
    }

    nav {
        padding-bottom: 40px;
    }

    .nav-links {
        display: none;
        margin-left: -200px;
        margin-top: 10px;
        width: 650px;
    }

    .project-card-box {
        width: 580px;
        height: 410px;
        margin: 40px auto;
    }


    
    .project-card-content-box{
        width: 580px;
        height: 410px;
        float: none;
        border-radius: 10px;
    }

    .show-links{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-top: 60px;
        animation-name: fadeIn;
        animation-duration: 900ms;
    }

    @keyframes fadeIn{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

}

/* ========================================================================= */

@media screen and (max-width: 785px) {
        #project-card-1, #project-card-2, #project-card-3,
        #project-card-4, #project-card-5, #project-card-6, #project-card-7,
         #project-card-jazz-guitar {
         background-image: unset; 
    }
}
/* ========================================================================= */

@media screen and (max-width: 600px) {
    .about-text {
        font-size: 1.2rem;
        max-width: 90%;
        text-align: left;
    }

    .anchor-btn {
        font-size: 1rem; 
        padding: 0.45em;
        padding: 0.65em;
    }

    button {
        width: 90px;
        height: 40px;
        font-size: 1.06rem;
    }
    .btn-group {
        width: 70%;
        bottom: 1.4rem;
    }
    .anchor-btn.btn-group-2,.anchor-btn.btn-group-5{
        margin-bottom: 20px;
    }

    .anchor-btn.btn-group-1, .anchor-btn.btn-group-3, .anchor-btn.btn-group-7{
        margin-bottom: 10px;
    }

    .footer-line {
        width: 90%;
    }

    #footer-message {
        font-size: 1rem;
    }

    .footer-scroll-up {
        margin-top: 60px;
    }

    .gregg-img {
        width: 90%;
        margin-top: 0px;
    }

    #header-name {
        font-size: 2.875rem;
        margin-bottom: 25px;
        margin-top: 60px;
    }

    #header-subtitle {
        font-size: 2.44rem;
        letter-spacing: -2.2px;
        margin-bottom: 60px;
    }

    nav {
        height: 90px;
        padding-bottom: 30px;
    }

    .nav-links {
        margin-top: 30px;
        width: 500px;
    }

    .nav-links > li {
        font-size: 1rem;
    }

    .nav-links > li a {
        text-decoration: none;
        color: #fff;
        -webkit-transition: all 100ms;
        -o-transition: all 100ms;
        transition: all 100ms;
    }

    .project-cards {
        margin-top: -60px;
        margin-bottom: 60px;
    }

    .project-card-box {
        width: 92%;
        height: 410px;
        margin: 40px auto 120px;
    }

    #project-card-1 {
        background-image: none; 
    }
    
    .project-card-content-box {
        width: 100%;
        height: 470px;
        float: none;
        border-radius: 10px;
        margin-top: 80px;
    }
    
    .project-case-study {
        color: #fff;
        font-size: 1.03rem;
        max-width: 95%;
        margin: 20px auto 0px;
        min-height: 90px;
        text-align: left;
    }

    .project-description {
        margin-top: 20px;
    }

    .project-number {
        font-size: 3.75rem;
        left: 27px;
    }
    .project-number-6 {
        left: 23px;
    }

    .project-title {
        font-size: 1.875rem;
        padding-top: 30px;
    }       

    .project-title-bank, .project-title-rhythmic-ear-trainer, .project-title-synth-explorer {
        max-width: 250px;
        margin: auto;
    }

    .project-title-synth-explorer, #project-title-guitar-website {
        padding-left: 30px;
    }

    .project-title-music-social-site {
        padding-top: 0px;
    }

    .project-title-ear-trainer {
        max-width: 200px;
        margin: auto;
        padding-top: 20px;
    }

    #project-title-guitar-website {
        margin-top: 0;
    }

    #project-title-music-library {
        max-width: 200px;
        margin: auto;
    }

    .project-subtitle {
        max-width: 300px;
        display: block;
        text-align: center;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
    }

    #project-subtitle-synth-explorer {
        margin-top: 20px;
    }

    .project-title-rhythmic-ear-trainer {
        margin-bottom: 20px;
    }

    .section-container {
        /* padding: 30px 0 100px; */
        padding: 0px 0 60px;
    }

    .skill-list {
        align-items: center;
        margin-top: -50px;
        margin-bottom: -50px;
        max-width: 100%;
        padding-left: 0px;
    }

    .skill {
        font-size: 0.9rem;
        max-width: 110px;
    }

    .skills-divider {
        max-width: 300px;
    }

    .show {
        width: 550px;
    }
    
    .tech, .tech2, .tech3 {
        font-size: 1.06rem;
        padding: 6px;
        line-height: 2.5;
    }
}

.intro-text {
    font-weight: bold;
    font-size: 1.6rem;
    color: #24456e;
}

#skillsSelect-section {
    margin-top: 100px;
    background-image: url("images/technology-background.jpg"), linear-gradient(rgb(29, 46, 129), rgb(50, 166, 238));
    background-size: cover;
    background-position: 50% 30%;
    background-blend-mode: soft-light;
}

#skills-header{
    color: #fff;
    text-shadow: 2px 1px 8px black;
}


